import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonText,
  IonToolbar,
  useIonModal,
} from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../redux/store";
import { RegisterType } from "../shared/register-type.enum";
import Login from "./Login";
import Register from "./Register";

const ContentWrapper = styled.div`
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 0px 20px;
`;

const AuthRequireWrapping: React.FC<{ component: () => React.ReactNode }> = ({
  component,
}) => {
  const { t } = useTranslation();
  const membership = useSelector((state: RootState) => state.membership);
  const [presentLoginModal, dismissLoginModal] = useIonModal(Login, {
    onModalDismiss: () => {
      dismissLoginModal();
    },
  });
  const [presentRegisterModal, dismissRegisterModal] = useIonModal(Register, {
    onModalDismiss: () => {
      dismissRegisterModal();
    },
    registerType: RegisterType.Register,
  });
  const onLoginClick = () => {
    presentLoginModal();
  };
  const onRegisterClick = () => {
    presentRegisterModal();
  };

  return (
    <IonPage>
      {membership?.auth ? (
        component()
      ) : (
        <>
          <IonHeader>
            <IonToolbar></IonToolbar>
          </IonHeader>
          <IonContent fullscreen>
            <IonHeader collapse="condense">
              <IonToolbar></IonToolbar>
            </IonHeader>
            <ContentWrapper>
              <IonText>
                <h1>{t("pleaseLogin")}</h1>
              </IonText>
              <IonButton size="large" expand="block" onClick={onLoginClick}>
                {t("login")}
              </IonButton>
              <IonText>
                <IonButton onClick={onRegisterClick} size="small" fill="clear">
                  <h6>{t("register")}</h6>
                </IonButton>
              </IonText>
            </ContentWrapper>
          </IonContent>
        </>
      )}
    </IonPage>
  );
};
export default AuthRequireWrapping;
