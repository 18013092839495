import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UtilityState {
  isLoading: boolean;
}

const initialState: UtilityState = {
  isLoading: false,
};

export const utilityState = createSlice({
  name: "utility",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setIsLoading } = utilityState.actions;

export default utilityState.reducer;
