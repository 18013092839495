import { ErrorMessage } from "@hookform/error-message";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import dayjs from "dayjs";
import { addOutline, trashOutline } from "ionicons/icons";
import { useCallback, useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import {
  getGroupBuyingDistricts,
  getOutlets,
  postGroupBuyingEvent,
} from "../../api/path";
import requestClient from "../../api/request-client";
import FileInput from "../../components/FileInput";
import { RootState } from "../../redux/store";
import { setShowSubmitSuccess } from "../../redux/toast.slice";
import { dateFormat } from "../../shared/constants";
import { uploadFileToAwsS3 } from "../../shared/helpers";
import {
  GroupBuyingDistrict,
  GroupBuyingEvent,
  Outlet,
  PickUpPoint,
} from "../../shared/types";

interface CreateEventFormData {
  eventName: string | null;
  outletId: string | null;
  districtId: string | null;
  launchTime: string | null;
  deliveryDate: string | null;
  pickUpPoints: PickUpPoint[];
}

const FormInputArea = styled.div`
  margin-bottom: 30px;
`;
const PickUpPointWrapper = styled.div`
  margin-bottom: 10px;
`;
const CreatePickUpPointBtnWrapper = styled.div`
  margin: 5px 10px;
`;

const CreateEvent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const deliveryDateOffsetDays: number = 2;

  const initialValue: CreateEventFormData = {
    eventName: null,
    outletId: null,
    districtId: null,
    launchTime: null,
    deliveryDate: null,
    pickUpPoints: [],
  };
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: { ...initialValue },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "pickUpPoints",
  });

  const [groupBuyingDistricts, setGroupBuyingDistricts] = useState<
    GroupBuyingDistrict[]
  >([]);
  const [outlets, setOutlets] = useState<Outlet[]>([]);
  const system = useSelector((state: RootState) => state.system);

  // const isFormValid =
  //   eventName &&
  //   eventName.trim().length > 0 &&
  //   outletId &&
  //   districtId &&
  //   launchTime &&
  //   deliveryDate &&
  //   pickUpPoints &&
  //   pickUpPoints.length > 0 &&
  //   pickUpPoints.every(
  //     (p) =>
  //       p.name &&
  //       p.name.trim().length > 0 &&
  //       p.time &&
  //       p.inChargePersonName &&
  //       p.inChargePersonName.trim().length > 0 &&
  //       p.inChargePersonMobile &&
  //       p.inChargePersonMobile.trim().length >= 8
  //   );

  const getGroupBuyingDistrictsCallback = useCallback(async () => {
    const response = await requestClient.get<GroupBuyingDistrict[]>(
      getGroupBuyingDistricts
    );
    setGroupBuyingDistricts(response.data);
  }, [setGroupBuyingDistricts]);
  const getOutletsCallback = useCallback(async () => {
    const response = await requestClient.get<Outlet[]>(getOutlets, {
      params: {
        filter: `companyId||eq||${system.groupBuyingOutletCompanyId}`,
      },
    });
    setOutlets(response.data);
  }, [setOutlets, system.groupBuyingOutletCompanyId]);
  useEffect(() => {
    getGroupBuyingDistrictsCallback();
    getOutletsCallback();
  }, [getGroupBuyingDistrictsCallback, getOutletsCallback]);

  const onPickUpPointCreateClick = () => {
    append({
      id: "",
      name: "",
      selectedImageFile: null,
      imageUrl: null,
      time: "",
      inChargePersonName: "",
      inChargePersonMobile: "",
    });
  };
  const onPickUpPointDeleteClick = (index: number) => {
    remove(index);
  };

  const maxPickUpPointTimeIntervalInMin = 30;
  const maxPickUpPointTimeIntervalInMS =
    maxPickUpPointTimeIntervalInMin * 60 * 1000;
  const isPickUpPointTimeValid = () => {
    if (getValues("pickUpPoints")?.length <= 0) {
      return false;
    }
    if (getValues("pickUpPoints")?.length > 3) {
      return false;
    }
    const times: Date[] = [];
    for (const time of getValues("pickUpPoints")?.map((p) => p.time)) {
      if (!time) {
        return false;
      }
      times.push(dayjs(`2022-01-01 ${time}`).toDate());
    }
    for (let i = 0; i < times.length - 1; i++) {
      const time = times[i].getTime();
      const nextTime = times[i + 1].getTime();
      if (Math.abs(nextTime - time) > maxPickUpPointTimeIntervalInMS) {
        return false;
      }
    }
    return true;
  };

  const onSubmit = async (data: CreateEventFormData) => {
    if (!isValid || !isPickUpPointTimeValid()) return;

    const submitPickUpPoints = data.pickUpPoints;
    for (const pickUpPoint of submitPickUpPoints) {
      pickUpPoint.time = dayjs(
        data.deliveryDate + " " + pickUpPoint.time
      ).toISOString();

      if (pickUpPoint.selectedImageFile) {
        pickUpPoint.imageUrl = await uploadFileToAwsS3(
          pickUpPoint.selectedImageFile
        );
        pickUpPoint.selectedImageFile = null;
      }
    }
    await requestClient.post<GroupBuyingEvent>(
      postGroupBuyingEvent.replace("{id}", id),
      {
        name: data.eventName,
        outletId: data.outletId,
        groupBuyingDistrictId: data.districtId,
        launchTime: dayjs(data.launchTime).toISOString(),
        deliveryDate: dayjs(data.deliveryDate).toISOString(),
        pickUpPoints: submitPickUpPoints,
      }
    );
    dispatch(setShowSubmitSuccess(true));
    history.replace(
      `/group-leader-management/edit/${id}?createdEvent=true&eventType=approvedEvent`
    );
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref={`/group-leader-management/edit/${id}`}
            />
          </IonButtons>
          <IonTitle>{t("createEvent")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{t("createEvent")}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <form onSubmit={handleSubmit(async (data) => await onSubmit(data))}>
          <FormInputArea>
            <IonList>
              <IonItem>
                <IonLabel position="stacked">{t("eventName")}</IonLabel>
                <Controller
                  name="eventName"
                  control={control}
                  rules={{ required: t("requiredField")?.toString() }}
                  render={({ field: { onChange, value } }) => (
                    <IonInput
                      type="text"
                      value={value}
                      onIonChange={onChange}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="eventName"
                  as={<IonText color="danger" />}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">{t("brand")}</IonLabel>
                <Controller
                  control={control}
                  name="outletId"
                  rules={{ required: t("requiredField")?.toString() }}
                  render={({ field }) => (
                    <IonSelect
                      value={field.value}
                      onIonChange={(e) => setValue("outletId", e.detail.value)}
                    >
                      {outlets.map((o) => (
                        <IonSelectOption value={o.id} key={o.id}>
                          {o.name}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="outletId"
                  as={<IonText color="danger" />}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">{t("district")}</IonLabel>
                <Controller
                  control={control}
                  name="districtId"
                  rules={{ required: t("requiredField")?.toString() }}
                  render={({ field }) => (
                    <IonSelect
                      value={field.value}
                      onIonChange={(e) =>
                        setValue("districtId", e.detail.value!)
                      }
                    >
                      {groupBuyingDistricts.map((d) => (
                        <IonSelectOption value={d.id} key={d.id}>
                          {d.name}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="districtId"
                  as={<IonText color="danger" />}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">{t("launchTime")}</IonLabel>
                <Controller
                  name="launchTime"
                  control={control}
                  rules={{ required: t("requiredField")?.toString() }}
                  render={({ field: { onChange, value } }) => (
                    <IonInput
                      type="datetime-local"
                      value={value}
                      min={dayjs().format("YYYY-MM-DDTHH:mm")}
                      onIonChange={(e) => {
                        onChange(e);
                        setValue("deliveryDate", null);
                      }}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="launchTime"
                  as={<IonText color="danger" />}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">{t("deliveryDate")}</IonLabel>
                <Controller
                  name="deliveryDate"
                  control={control}
                  rules={{
                    required: t("requiredField")?.toString(),
                    validate: {
                      compareLaunchTime: (value) =>
                        dayjs(value).diff(
                          dayjs(getValues("launchTime")),
                          "day"
                        ) >= deliveryDateOffsetDays ||
                        t("deliveryDateMustBeLaterThanLaunchDate", {
                          days: deliveryDateOffsetDays,
                        })?.toString(),
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <IonInput
                      type="date"
                      value={value}
                      min={
                        getValues("launchTime")
                          ? dayjs(getValues("launchTime"))
                              .add(deliveryDateOffsetDays, "day")
                              .format("YYYY-MM-DD")
                          : undefined
                      }
                      onIonChange={onChange}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="deliveryDate"
                  as={<IonText color="danger" />}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">{t("cutOffTime")}</IonLabel>
                {getValues("deliveryDate") && (
                  <IonInput
                    type="text"
                    disabled={true}
                    value={dayjs(getValues("deliveryDate"))
                      .add(-system.groupBuyingCutOffDays, "d")
                      .format(dateFormat)}
                  />
                )}
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">{t("pickUpPoint")}</IonLabel>
                <IonList>
                  {fields.map((item, index) => (
                    <PickUpPointWrapper key={index}>
                      <IonItem>
                        <IonList>
                          <IonItem>
                            <IonLabel position="stacked">
                              {t("pickUpPointName")}
                            </IonLabel>
                            <Controller
                              name={`pickUpPoints.${index}.name`}
                              control={control}
                              defaultValue={item.name}
                              rules={{
                                required: t("requiredField")?.toString(),
                              }}
                              render={({ field: { onChange, value } }) => (
                                <IonInput
                                  type="text"
                                  value={value}
                                  onIonChange={onChange}
                                />
                              )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name={`pickUpPoints.${index}.name`}
                              as={<IonText color="danger" />}
                            />
                          </IonItem>
                          <IonItem>
                            <IonLabel position="stacked">
                              {t("pickUpPointPhoto")}
                            </IonLabel>
                            <Controller
                              name={`pickUpPoints.${index}.selectedImageFile`}
                              control={control}
                              defaultValue={item.selectedImageFile}
                              render={() => (
                                <FileInput
                                  type="file"
                                  accept="image/*"
                                  onChange={(event) =>
                                    setValue(
                                      `pickUpPoints.${index}.selectedImageFile`,
                                      event?.target?.files
                                        ? event.target.files[0]
                                        : null
                                    )
                                  }
                                />
                              )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name={`pickUpPoints.${index}.selectedImageFile`}
                              as={<IonText color="danger" />}
                            />
                          </IonItem>
                          <IonItem>
                            <IonLabel position="stacked">
                              {t("pickUpTime")}
                            </IonLabel>
                            <Controller
                              name={`pickUpPoints.${index}.time`}
                              control={control}
                              defaultValue={item.time}
                              rules={{
                                required: t("requiredField")?.toString(),
                              }}
                              render={({ field: { onChange, value } }) => (
                                <IonInput
                                  type="time"
                                  value={value}
                                  onIonChange={onChange}
                                />
                              )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name={`pickUpPoints.${index}.time`}
                              as={<IonText color="danger" />}
                            />
                          </IonItem>
                          <IonItem>
                            <IonLabel position="stacked">
                              {t("inChargePersonName")}
                            </IonLabel>
                            <Controller
                              name={`pickUpPoints.${index}.inChargePersonName`}
                              control={control}
                              defaultValue={item.inChargePersonName}
                              rules={{
                                required: t("requiredField")?.toString(),
                              }}
                              render={({ field: { onChange, value } }) => (
                                <IonInput
                                  type="text"
                                  value={value}
                                  onIonChange={onChange}
                                />
                              )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name={`pickUpPoints.${index}.inChargePersonName`}
                              as={<IonText color="danger" />}
                            />
                          </IonItem>
                          <IonItem>
                            <IonLabel position="stacked">
                              {t("inChargePersonMobile")}
                            </IonLabel>
                            <Controller
                              name={`pickUpPoints.${index}.inChargePersonMobile`}
                              control={control}
                              defaultValue={item.inChargePersonMobile}
                              rules={{
                                required: t("requiredField")?.toString(),
                                minLength: {
                                  value: 8,
                                  message: t("atLeastNDigits", {
                                    n: 8,
                                  })?.toString(),
                                },
                              }}
                              render={({ field: { onChange, value } }) => (
                                <IonInput
                                  type="text"
                                  value={value}
                                  onIonChange={onChange}
                                />
                              )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name={`pickUpPoints.${index}.inChargePersonMobile`}
                              as={<IonText color="danger" />}
                            />
                          </IonItem>
                        </IonList>
                        <IonNote slot="start">{index + 1}</IonNote>
                        <IonNote slot="end">
                          <IonButton
                            size="small"
                            fill="outline"
                            color="danger"
                            onClick={() => onPickUpPointDeleteClick(index)}
                          >
                            <IonIcon icon={trashOutline} />
                          </IonButton>
                        </IonNote>
                      </IonItem>
                    </PickUpPointWrapper>
                  ))}
                </IonList>
              </IonItem>
              <CreatePickUpPointBtnWrapper>
                <IonButton
                  size="small"
                  fill="outline"
                  expand="full"
                  onClick={onPickUpPointCreateClick}
                  color="success"
                  disabled={getValues("pickUpPoints")?.length >= 3}
                >
                  <IonIcon icon={addOutline} /> {t("createPickUpPoint")}
                </IonButton>
              </CreatePickUpPointBtnWrapper>
            </IonList>
          </FormInputArea>
          {!isPickUpPointTimeValid() && (
            <IonLabel color="danger" position="stacked">
              <h4>
                {t("pickUpPointMsg", {
                  pickUpPointTimeIntervalInMin: maxPickUpPointTimeIntervalInMin,
                })}
              </h4>
            </IonLabel>
          )}
          <IonButton expand="block" type="submit">
            {t("submit")}
          </IonButton>
        </form>
      </IonContent>
    </>
  );
};

export default CreateEvent;
