import dayjs from "dayjs";
import awsRequestClient from "../api/aws-request-client";
import { getS3PutObjectSignedUrl } from "../api/path";
import requestClient from "../api/request-client";
import { EventStatus } from "./event-status.enum";
import { GroupBuyingEvent, GroupBuyingGroup } from "./types";

export const toCurrency = (value: number): string =>
  value?.toLocaleString("en-US", {
    style: "currency",
    currency: "HKD",
  });

export const getCSOGroupBuyingUrl = (
  group: GroupBuyingGroup,
  event: GroupBuyingEvent
): string | undefined => {
  const url = process.env.REACT_APP_CSO_GROUPEPE_URL?.replace(
    "{outletId}",
    event.outlet.id.toString()
  )
    .replace("{groupId}", group.id.toString())
    .replace("{eventId}", event.id.toString());
  return url;
};

export const isEventActive = (
  e: GroupBuyingEvent,
  groupBuyingCutOffDays: number
) => {
  if (dayjs() < dayjs(e.launchTime)) return false;
  if (
    dayjs() >
    dayjs(e.deliveryDate)
      .add(-groupBuyingCutOffDays, "d")
      .hour(23)
      .minute(59)
      .second(0)
      .millisecond(0)
  )
    return false;
  if (e.status !== EventStatus.Approved) return false;

  return true;
};

export const uploadFileToAwsS3 = async (file: File): Promise<string | null> => {
  const s3SignedUrlResponse = await requestClient.get<string>(
    getS3PutObjectSignedUrl + file.name
  );
  const s3SignedUrl = s3SignedUrlResponse.data;
  await awsRequestClient.put(s3SignedUrl, file);
  return s3SignedUrl.split("?")[0];
};
