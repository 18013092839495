import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorCode } from "../shared/error-code.enum";

interface ToastState {
  showSubmitSuccess: boolean;
  showApiError: boolean;
  errorCode?: ErrorCode;
}

interface ShowApiErrorPayload {
  errorCode?: ErrorCode;
  isShow: boolean;
}

const initialState: ToastState = {
  showSubmitSuccess: false,
  showApiError: false,
};

export const toastState = createSlice({
  name: "toast",
  initialState,
  reducers: {
    setShowSubmitSuccess: (state, action: PayloadAction<boolean>) => {
      state.showSubmitSuccess = action.payload;
    },
    setShowApiError: (state, action: PayloadAction<ShowApiErrorPayload>) => {
      state.showApiError = action.payload.isShow;
      state.errorCode = action.payload.errorCode;
    },
  },
});

export const { setShowSubmitSuccess, setShowApiError } = toastState.actions;
export default toastState.reducer;
