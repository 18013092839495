import styled from "styled-components";

const GroupImage = styled.img`
  margin-bottom: 15px;
  max-height: 200px;
  width: 100%;
  object-fit: contain;
`;

export default GroupImage;
