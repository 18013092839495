import {
  IonContent,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonText,
  IonTitle,
  IonToolbar,
  RefresherEventDetail,
} from "@ionic/react";
import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import { getGroupBuyingGroups } from "../api/path";
import requestClient from "../api/request-client";
import GroupCard from "../components/GroupCard";
import { setPublicGroup } from "../redux/public-group.slice";
import { RootState } from "../redux/store";

const Hints = styled.h4`
  text-align: center;
`;

const AvailableGroup: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const publicGroup = useSelector((state: RootState) => state.publicGroup);
  const ionInfiniteScrollRef: React.RefObject<HTMLIonInfiniteScrollElement> =
    useRef(null);
  const limitSize: number = 5;
  const isMoreToLoad: boolean = publicGroup.total > publicGroup.data.length;
  const sort: string = "createdAt,DESC";
  const filter: string = "";
  // const filter: string = "isPrivateGroup||$eq||false";

  const getPublicGroupApi = async (parameters: any): Promise<any> => {
    const response = await requestClient.get(getGroupBuyingGroups, {
      params: parameters,
      data: {
        ignoreLoading: true,
      },
    });
    return response.data;
  };
  const getLatestPublicGroupCallback = useCallback(async () => {
    const json = await getPublicGroupApi({
      limit: limitSize.toString(),
      page: "1",
      sort,
      filter,
    });
    dispatch(setPublicGroup(json));
  }, [dispatch]);
  useEffect(() => {
    getLatestPublicGroupCallback();
  }, [getLatestPublicGroupCallback]);

  const onScrollLoadData = async (e: CustomEvent<void>) => {
    const responseData = await getPublicGroupApi({
      limit: limitSize.toString(),
      page: (publicGroup.page + 1).toString(),
      filter,
      sort,
    });
    dispatch(
      setPublicGroup({
        data: [...publicGroup.data, ...responseData.data],
        count: responseData.count,
        page: responseData.page,
        total: responseData.total,
      })
    );
    ionInfiniteScrollRef?.current?.complete();
  };
  const onRefresh = async (e: CustomEvent<RefresherEventDetail>) => {
    try {
      await getLatestPublicGroupCallback();
    } finally {
      e.detail.complete();
    }
  };

  const cardOnClick = (id: number) => {
    history.push(`/available-group/${id.toString()}`);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t("onGoingGroupBuying")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{t("onGoingGroupBuying")}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonText color="medium">
          <Hints>{t("chooseTheGroupThatYouAreInterested")}</Hints>
        </IonText>
        {publicGroup?.data?.map((g) => (
          <GroupCard
            key={g.id}
            groupBuyingGroup={g}
            onClick={() => cardOnClick(g.id)}
          />
        ))}
        <IonInfiniteScroll
          ref={ionInfiniteScrollRef}
          threshold="50px"
          onIonInfinite={onScrollLoadData}
          disabled={!isMoreToLoad}
        >
          <IonInfiniteScrollContent loadingSpinner="bubbles"></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
};

export default AvailableGroup;
