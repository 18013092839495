import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import styled from "styled-components";
import { getGroupBuyingGroup } from "../api/path";
import requestClient from "../api/request-client";
import GroupImage from "../components/GroupImage";
import { RootState } from "../redux/store";
import { dateFormat, dateTimeFormat } from "../shared/constants";
import {
  getCSOGroupBuyingUrl,
  isEventActive,
  toCurrency,
} from "../shared/helpers";
import { GroupBuyingEvent, GroupBuyingGroup } from "../shared/types";

const GroupLeader = styled.h3`
  text-align: center;
`;
const EventArea = styled.div`
  margin: 25px 15px;
`;
const PickupPointWrapper = styled.div`
  margin-left: 10px;
`;
const PickUpPoint = styled.div`
  margin: 5px 0px;
`;
const EventTitle = styled.div`
  font-weight: bold;
  font-size: 30px;
`;
const EventDesc = styled.div`
  margin: 5px 0px;
`;

const GroupDetail: React.FC = () => {
  const { t } = useTranslation();
  const [presentOrderAlert] = useIonAlert();
  const { id } = useParams<{ id: string }>();
  const [groupBuyingGroup, setGroupBuyingGroup] = useState<GroupBuyingGroup>();
  const system = useSelector((state: RootState) => state.system);

  const getGroupBuyingGroupCallback = useCallback(async () => {
    const response = await requestClient.get<GroupBuyingGroup>(
      getGroupBuyingGroup + id
    );
    const responseBody = response.data;
    setGroupBuyingGroup(responseBody);
  }, [id]);
  useEffect(() => {
    getGroupBuyingGroupCallback();
  }, [getGroupBuyingGroupCallback]);

  const onOrderNowClick = (event: GroupBuyingEvent) => {
    if (!groupBuyingGroup) return;
    const url = getCSOGroupBuyingUrl(groupBuyingGroup, event);
    if (!url) return;

    presentOrderAlert({
      header: t("orderNow"),
      message: t("orderNowMsg"),
      buttons: [
        t("cancel"),
        {
          text: t("confirm"),
          handler: (d) => {
            const newWindow = window.open(url, "_blank", "noopener,noreferrer");
            if (newWindow) newWindow.opener = null;
          },
        },
      ],
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/available-group" />
          </IonButtons>
          <IonTitle>{groupBuyingGroup?.name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{groupBuyingGroup?.name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <GroupImage
          src={
            groupBuyingGroup?.imageUrl ||
            "https://d1.biteos.com/cas2/uploads/brand/groupepe"
          }
        />
        <GroupLeader>
          {t("groupLeader")}: {groupBuyingGroup?.leader?.name}
        </GroupLeader>
        <EventArea>
          <IonText>
            <h2>{t("currentEvent")}</h2>
          </IonText>
          <IonList>
            {groupBuyingGroup?.groupBuyingEvents
              .filter((event) =>
                isEventActive(event, system.groupBuyingCutOffDays)
              )
              .map((event) => (
                <IonItem key={event.id}>
                  <IonLabel>
                    <EventTitle>{event.name}</EventTitle>
                    <EventDesc>{event.outlet.name}</EventDesc>
                    <EventDesc>
                      {t("deliveryDate")}:{" "}
                      {dayjs(event.deliveryDate).format(dateFormat)}
                    </EventDesc>
                    <EventDesc>
                      {t("cutOffTime")}:{" "}
                      {dayjs(event.deliveryDate)
                        .add(-system.groupBuyingCutOffDays, "d")
                        .hour(23)
                        .minute(59)
                        .format(dateTimeFormat)}
                    </EventDesc>
                    <EventDesc>
                      {t("minOfPurchase")}: {toCurrency(event.minAmount)}
                    </EventDesc>
                    <EventDesc>{t("pickUpPoint")}:</EventDesc>
                    <PickupPointWrapper>
                      {event.groupBuyingPickUpPoints.map((pickUpPoint) => (
                        <PickUpPoint key={pickUpPoint.id}>
                          <div>
                            <h2>{pickUpPoint.name}</h2>
                            <h2>
                              {dayjs(pickUpPoint.time).format(dateTimeFormat)}
                            </h2>
                          </div>
                        </PickUpPoint>
                      ))}
                    </PickupPointWrapper>
                  </IonLabel>
                  <IonButton
                    color="primary"
                    size="default"
                    onClick={() => onOrderNowClick(event)}
                  >
                    {t("orderNow")}
                  </IonButton>
                </IonItem>
              ))}
          </IonList>
        </EventArea>
      </IonContent>
    </IonPage>
  );
};

export default GroupDetail;
