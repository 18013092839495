import {
  IonButton,
  IonContent,
  IonHeader,
  IonText,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { logout } from "../../api/path";
import requestClient from "../../api/request-client";
import { setMembership } from "../../redux/membership.slice";
import { RootState } from "../../redux/store";

const ContentWrapper = styled.div`
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 0px 20px;
`;

const Membership: React.FC = () => {
  const { t } = useTranslation();
  const [present] = useIonAlert();
  const dispatch = useDispatch();
  const membership = useSelector((state: RootState) => state.membership);

  const onLogoutClick = () => {
    present({
      message: t("logoutConfirm"),
      buttons: [
        t("cancel"),
        {
          text: t("confirm"),
          handler: async (d) => {
            try {
              await requestClient.post(logout);
            } catch {
            } finally {
              dispatch(setMembership({ auth: null }));
            }
          },
        },
      ],
    });
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t("membership")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{t("membership")}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <ContentWrapper>
          <IonText>
            <h1>{t("loggedInAs")}</h1>
          </IonText>
          <IonText>
            <h3>{membership?.auth?.username}</h3>
          </IonText>
          <IonButton expand="block" color="danger" onClick={onLogoutClick}>
            {t("logout")}
          </IonButton>
        </ContentWrapper>
      </IonContent>
    </>
  );
};

export default Membership;
