import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  useIonModal,
  useIonToast,
} from "@ionic/react";
import { useCallback, useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { login } from "../api/path";
import requestClient from "../api/request-client";
import { setMembership } from "../redux/membership.slice";
import { setShowSubmitSuccess } from "../redux/toast.slice";
import { RegisterType } from "../shared/register-type.enum";
import { AuthState } from "../shared/types";
import Register from "./Register";

const RecaptchaDescription = styled.div`
  color: var(--ion-color-medium);
  font-size: 10px;
  padding: 5px 10px;
`;

const Login: React.FC<{ onModalDismiss: () => void }> = ({
  onModalDismiss,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
    const token = await executeRecaptcha("customerLogin");
    setRecaptchaToken(token);
  }, [executeRecaptcha]);
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const [countryCode, setCountryCode] = useState<string | undefined>("852");
  const [mobileNum, setMobileNum] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>();
  const [presentRegisterModal, dismissRegisterModal] = useIonModal(Register, {
    onModalDismiss: () => {
      dismissRegisterModal();
    },
    registerType: RegisterType.Reset,
    initialMobileNumber: mobileNum,
  });

  const isFormValid =
    countryCode &&
    mobileNum &&
    mobileNum.length >= 8 &&
    password &&
    password.length >= 8;

  const [presentToast, _] = useIonToast();

  const onSubmit = async () => {
    if (!isFormValid) return;
    await handleReCaptchaVerify();
    try {
      const response = await requestClient.post<AuthState>(
        login,
        {
          username: countryCode + mobileNum,
          password: password,
        },
        {
          headers: {
            recaptcha: recaptchaToken || "",
          },
        }
      );
      const responseBody = response.data;
      dispatch(setMembership({ auth: responseBody }));
      dispatch(setShowSubmitSuccess(true));
      onModalDismiss();
    } catch (err: any) {
      const response = err.response;
      if (response && response.status === 401) {
        presentToast({
          message: t("usernameOrPasswordIncorrect"),
          color: "danger",
          duration: 3000,
        });
      } else if (response && response.status === 400) {
        let error = t("googleRecaptchaFailed", {
          error: response.data?.message,
        });
        if (response.data?.error === "CustomerIsDisabled") {
          error = t("customerDisabled");
        }
        presentToast({ message: error, color: "danger", duration: 3000 });
      }
    }
  };

  const onRegisterClick = () => {
    onModalDismiss();
    presentRegisterModal();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t("login")}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onModalDismiss}>{t("close")}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList>
          <IonItem>
            <IonLabel position="stacked">{t("countryCode")}</IonLabel>
            <IonSelect
              value={countryCode}
              onIonChange={(e) => setCountryCode(e.detail.value)}
            >
              <IonSelectOption value="852">+852 HK</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">{t("mobileNum")}</IonLabel>
            <IonInput
              type="text"
              value={mobileNum}
              onIonChange={(e) => setMobileNum(e.detail.value!)}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">{t("password")}</IonLabel>
            <IonInput
              type="password"
              value={password}
              onIonChange={(e) => setPassword(e.detail.value!)}
            />
          </IonItem>
        </IonList>
        <IonButton disabled={!isFormValid} expand="block" onClick={onSubmit}>
          {t("confirm")}
        </IonButton>
        <IonButton
          size="small"
          fill="clear"
          expand="block"
          onClick={() => onRegisterClick()}
        >
          {t("resetPassword")}
        </IonButton>
        <RecaptchaDescription>
          This site is protected by reCAPTCHA and the Google{" "}
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
          <a href="https://policies.google.com/terms">Terms of Service</a>{" "}
          apply.
        </RecaptchaDescription>
      </IonContent>
    </IonPage>
  );
};

export default Login;
