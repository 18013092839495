import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import { useCallback, useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { getVerificationCode, login, setPasswordApi } from "../api/path";
import requestClient from "../api/request-client";
import { setMembership } from "../redux/membership.slice";
import { setShowSubmitSuccess } from "../redux/toast.slice";
import { RegisterType } from "../shared/register-type.enum";
import { AuthState } from "../shared/types";

const RecaptchaDescription = styled.div`
  color: var(--ion-color-medium);
  font-size: 10px;
  padding: 5px 10px;
`;
const RegisterReminder = styled.p`
  color: var(--ion-color-dark);
  font-size: 15px;
  padding: 5px 10px;
`;
const GetVerificationCodeButton = styled(IonButton)`
  margin-top: 25px;
`;

const Register: React.FC<{
  onModalDismiss: () => void;
  registerType: RegisterType;
  initialMobileNumber: string;
}> = ({ onModalDismiss, registerType, initialMobileNumber }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [presentToast] = useIonToast();

  const [countryCode, setCountryCode] = useState<string | undefined>("852");
  const [mobileNum, setMobileNum] = useState<string | undefined>(
    initialMobileNumber
  );
  const [verificationCode, setVerificationCode] = useState<string>();
  const [password, setPassword] = useState<string | undefined>();
  const [confirmPassword, setconfirmPassword] = useState<string | undefined>();
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>();
  const [verificationCodeCountDown, setVerificationCodeCountDown] =
    useState<number>(0);

  useEffect(() => {
    if (verificationCodeCountDown > 0) {
      setTimeout(() => {
        setVerificationCodeCountDown(verificationCodeCountDown - 1);
      }, 1000);
    }
  }, [verificationCodeCountDown]);

  const isFormValid =
    countryCode &&
    mobileNum &&
    mobileNum.length >= 8 &&
    verificationCode &&
    password &&
    password.length >= 8 &&
    confirmPassword === password;

  const isGetVerificationCodeEnabled = (countryCode &&
    countryCode.length > 0 &&
    mobileNum &&
    mobileNum.length >= 8) as boolean;

  const onGetVerificationCode = async () => {
    try {
      await requestClient.post(getVerificationCode, {
        callingCode: countryCode,
        tel: mobileNum,
      });
      setVerificationCodeCountDown(
        parseInt(
          process.env.REACT_APP_SMS_VERIFICATION_CODE_COUNT_DOWN_SECONDS || "0"
        )
      );
    } catch (ex: any) {
      if (ex?.response?.status?.toString() === "500") {
        presentToast({
          message: t("smsFailed"),
          color: "danger",
          position: "middle",
          duration: 5000,
          buttons: [
            {
              text: t("close"),
              role: "cancel",
            },
          ],
        });
      }
    }
  };
  const handleReCaptchaVerify = useCallback(
    async (action: string) => {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return;
      }
      const token = await executeRecaptcha(action);
      setRecaptchaToken(token);
      return token;
    },
    [executeRecaptcha]
  );
  useEffect(() => {
    handleReCaptchaVerify("customerSetPassword");
  }, [handleReCaptchaVerify]);

  const onSubmit = async () => {
    if (!isFormValid) return;
    try {
      await handleReCaptchaVerify("customerSetPassword");
      await requestClient.post(
        setPasswordApi,
        {
          callingCode: countryCode,
          tel: mobileNum,
          password: password,
          otpToken: verificationCode,
        },
        {
          headers: {
            recaptcha: recaptchaToken || "",
          },
        }
      );

      const newRecaptchaToken = await handleReCaptchaVerify("customerLogin");
      const loginResponse = await requestClient.post<AuthState>(
        login,
        {
          username: countryCode + mobileNum,
          password: password,
        },
        {
          headers: {
            recaptcha: newRecaptchaToken || "",
          },
        }
      );
      const responseBody = loginResponse.data;
      dispatch(setMembership({ auth: responseBody }));
      dispatch(setShowSubmitSuccess(true));
      onModalDismiss();
    } catch (err: any) {
      const response = err.response;
      if (
        response &&
        response.status === 401 &&
        (response.data?.error === "OTPTokenInvalid" ||
          response.data?.error === "CustomerAccountNotCreated")
      ) {
        presentToast({
          message: t("verificationCodeIncorrect"),
          color: "danger",
          duration: 3000,
        });
      } else if (response && response.status === 401) {
        presentToast({
          message: t("usernameOrPasswordIncorrect"),
          color: "danger",
          duration: 3000,
        });
      } else if (response && response.status === 400) {
        let error = t("googleRecaptchaFailed", {
          error: response.data?.message,
        });
        if (response.data?.error === "CustomerIsDisabled") {
          error = t("customerDisabled");
        }
        presentToast({ message: error, color: "danger", duration: 3000 });
      }
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            {registerType === RegisterType.Reset
              ? t("resetPassword")
              : t("register")}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onModalDismiss}>{t("close")}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList>
          <IonItem>
            <IonLabel position="stacked">{t("countryCode")}</IonLabel>
            <IonSelect
              value={countryCode}
              onIonChange={(e) => setCountryCode(e.detail.value)}
            >
              <IonSelectOption value="852">+852 HK</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">{t("mobileNum")}</IonLabel>
            <IonInput
              type="text"
              value={mobileNum}
              onIonChange={(e) => setMobileNum(e.detail.value!)}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">{t("verificationCode")}</IonLabel>
            <IonInput
              type="text"
              value={verificationCode}
              onIonChange={(e) => setVerificationCode(e.detail.value!)}
            />
            <GetVerificationCodeButton
              size="small"
              color="tertiary"
              slot="end"
              disabled={
                !isGetVerificationCodeEnabled || verificationCodeCountDown > 0
              }
              onClick={onGetVerificationCode}
            >
              {verificationCodeCountDown > 0
                ? verificationCodeCountDown.toString() + "s"
                : t("getVerificationCode")}
            </GetVerificationCodeButton>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">{t("password")}</IonLabel>
            <IonInput
              type="password"
              value={password}
              onIonChange={(e) => setPassword(e.detail.value!)}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">{t("confirmPassword")}</IonLabel>
            <IonInput
              type="password"
              value={confirmPassword}
              onIonChange={(e) => setconfirmPassword(e.detail.value!)}
            />
          </IonItem>
        </IonList>
        <IonButton disabled={!isFormValid} expand="block" onClick={onSubmit}>
          {t("confirm")}
        </IonButton>
        <RegisterReminder>{t("registerReminder")}</RegisterReminder>
        <RecaptchaDescription>
          This site is protected by reCAPTCHA and the Google{" "}
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
          <a href="https://policies.google.com/terms">Terms of Service</a>{" "}
          apply.
        </RecaptchaDescription>
      </IonContent>
    </IonPage>
  );
};
export default Register;
