import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonTitle,
  IonToolbar,
  useIonActionSheet,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import copy from "copy-to-clipboard";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import styled from "styled-components";
import { getManagedGroupBuyingGroup } from "../../api/path";
import requestClient from "../../api/request-client";
import GroupImage from "../../components/GroupImage";
import { RootState } from "../../redux/store";
import { dateFormat, dateTimeFormat } from "../../shared/constants";
import { EventStatus } from "../../shared/event-status.enum";
import {
  getCSOGroupBuyingUrl,
  isEventActive,
  toCurrency,
} from "../../shared/helpers";
import { GroupBuyingEvent, GroupBuyingGroup } from "../../shared/types";

const EventArea = styled.div`
  margin: 25px 15px;
`;
const EventHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
const CreateEventBtnWrapper = styled.div`
  margin-top: 18px;
`;
const PickupPointWrapper = styled.div`
  margin-left: 10px;
`;
const PickUpPoint = styled.div`
  margin: 5px 0px;
`;
const EventActionsWrapper = styled.div`
  text-align: center;
`;
const EventAction = styled.div`
  display: block;
  margin: 0px auto;
`;
const GroupVisibility = styled.div`
  text-align: center;
`;
const EventTitle = styled.div`
  font-weight: bold;
  font-size: 30px;
`;
const EventDesc = styled.div`
  margin: 5px 0px;
`;

const GroupManagementEdit: React.FC = () => {
  const { t } = useTranslation();
  const [presentInviteFriendActionSheet] = useIonActionSheet();
  const [presentOrderAlert] = useIonAlert();
  const [presentToast] = useIonToast();
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const history = useHistory();
  const [groupBuyingGroup, setGroupBuyingGroup] = useState<GroupBuyingGroup>();
  const [eventType, setEventType] = useState<string | undefined>("approved");
  const system = useSelector((state: RootState) => state.system);

  const getGroupBuyingGroupCallback = useCallback(async () => {
    const response = await requestClient.get<GroupBuyingGroup>(
      getManagedGroupBuyingGroup + id
    );
    const responseBody = response.data;
    setGroupBuyingGroup(responseBody);
  }, [id]);
  useEffect(() => {
    getGroupBuyingGroupCallback();
  }, [getGroupBuyingGroupCallback]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const createdEvent = params.get("createdEvent");
    if (createdEvent) {
      getGroupBuyingGroupCallback();
      setEventType("pending");
      history.replace(`/group-leader-management/edit/${id}`);
    }
  }, [getGroupBuyingGroupCallback, history, id, location.search]);

  const getStatusColor = (status: EventStatus) => {
    switch (status) {
      case EventStatus.Approved:
        return "success";
      case EventStatus.Pending:
        return "warning";
      default:
        return "";
    }
  };

  const onInviteFriendClick = (event: GroupBuyingEvent) => {
    if (!groupBuyingGroup) return;
    const url = getCSOGroupBuyingUrl(groupBuyingGroup, event);
    if (!url) return;

    const shareText = t("csoShareText") + " \n" + url;

    presentInviteFriendActionSheet({
      header: t("inviteFriends"),
      buttons: [
        { text: t("cancel"), role: "cancel" },
        {
          text: t("copyLink"),
          handler: () => {
            copy(shareText);
            presentToast({
              message: t("copyCompletedMsg"),
              duration: 1500,
            });
          },
        },
        {
          text: t("shareViaWhatsapp"),
          handler: () => {
            const whatsappUrl =
              "https://wa.me/?text=" + encodeURIComponent(shareText);
            const newWindow = window.open(
              whatsappUrl,
              "_blank",
              "noopener,noreferrer"
            );
            if (newWindow) newWindow.opener = null;
          },
        },
        {
          text: t("shareMenu"),
          handler: () => {
            navigator.share({
              text: shareText,
            });
          },
        },
      ],
    });
  };

  const onOrderNowClick = (event: GroupBuyingEvent) => {
    if (!groupBuyingGroup) return;
    const url = getCSOGroupBuyingUrl(groupBuyingGroup, event);
    if (!url) return;

    presentOrderAlert({
      header: t("orderNow"),
      message: t("orderNowMsg"),
      buttons: [
        t("cancel"),
        {
          text: t("confirm"),
          handler: (d) => {
            const newWindow = window.open(url, "_blank", "noopener,noreferrer");
            if (newWindow) newWindow.opener = null;
          },
        },
      ],
    });
  };

  const isEventExpired = (e: GroupBuyingEvent) => {
    if (dayjs() > dayjs(e.deliveryDate).add(-system.groupBuyingCutOffDays, "d"))
      return true;
    return false;
  };

  const getEventsByType = (type: string | undefined) => {
    switch (type) {
      case "approved":
        return groupBuyingGroup?.groupBuyingEvents?.filter(
          (e) => e.status === EventStatus.Approved && !isEventExpired(e)
        );
      case "pending":
        return groupBuyingGroup?.groupBuyingEvents?.filter(
          (e) => e.status === EventStatus.Pending && !isEventExpired(e)
        );
      case "expired":
        return groupBuyingGroup?.groupBuyingEvents?.filter(isEventExpired);
      default:
        return groupBuyingGroup?.groupBuyingEvents;
    }
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/group-leader-management" />
          </IonButtons>
          <IonTitle>{groupBuyingGroup?.name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{groupBuyingGroup?.name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <GroupImage
          src={
            groupBuyingGroup?.imageUrl ||
            "https://d1.biteos.com/cas2/uploads/brand/groupepe"
          }
        />
        <GroupVisibility>
          <IonText>
            <h3>
              {groupBuyingGroup?.isPrivateGroup
                ? t("privateGroup")
                : t("publicGroup")}
            </h3>
          </IonText>
        </GroupVisibility>
        <EventArea>
          <EventHeader>
            <IonText>
              <h2>{t("event")}</h2>
            </IonText>
            <CreateEventBtnWrapper>
              <IonButton
                size="small"
                color="success"
                onClick={() =>
                  history.push(
                    `/group-leader-management/edit/${groupBuyingGroup?.id}/create-event`
                  )
                }
              >
                {t("createEvent")}
              </IonButton>
            </CreateEventBtnWrapper>
          </EventHeader>
          <IonSegment
            value={eventType}
            onIonChange={(e) => setEventType(e.detail.value)}
          >
            <IonSegmentButton value="approved" layout="icon-start">
              <IonLabel>
                {t("approvedEvent")} ({getEventsByType("approved")?.length})
              </IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="pending" layout="icon-start">
              <IonLabel>
                {t("pendingEvent")} ({getEventsByType("pending")?.length})
              </IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="expired" layout="icon-start">
              <IonLabel>
                {t("expiredEvent")} ({getEventsByType("expired")?.length})
              </IonLabel>
            </IonSegmentButton>
          </IonSegment>
          <IonList>
            {getEventsByType(eventType)?.map((event) => (
              <IonItem key={event.id}>
                <IonLabel>
                  <EventTitle>{event.name}</EventTitle>
                  <IonText color={getStatusColor(event.status)}>
                    <EventDesc>{t(event.status)}</EventDesc>
                  </IonText>
                  <EventDesc>{event.outlet.name}</EventDesc>
                  <EventDesc>
                    {t("launchTime")}:{" "}
                    {dayjs(event.launchTime).format(dateTimeFormat)}
                  </EventDesc>
                  <EventDesc>
                    {t("deliveryDate")}:{" "}
                    {dayjs(event.deliveryDate).format(dateFormat)}
                  </EventDesc>
                  <EventDesc>
                    {t("cutOffTime")}:{" "}
                    {dayjs(event.deliveryDate)
                      .add(-system.groupBuyingCutOffDays, "d")
                      .hour(23)
                      .minute(59)
                      .format(dateTimeFormat)}
                  </EventDesc>
                  <EventDesc>
                    {t("minOfPurchase")}: {toCurrency(event.minAmount)}
                  </EventDesc>
                  <EventDesc>{t("pickUpPoint")}:</EventDesc>
                  <PickupPointWrapper>
                    {event.groupBuyingPickUpPoints?.map((pickUpPoint) => (
                      <PickUpPoint key={pickUpPoint.id}>
                        <div>
                          <h2>{pickUpPoint.name}</h2>
                          <h2>
                            {dayjs(pickUpPoint.time).format(dateTimeFormat)}
                          </h2>
                        </div>
                      </PickUpPoint>
                    ))}
                  </PickupPointWrapper>
                </IonLabel>
                {isEventActive(event, system.groupBuyingCutOffDays) && (
                  <EventActionsWrapper>
                    <EventAction>
                      <IonButton
                        color="secondary"
                        size="small"
                        onClick={() => onInviteFriendClick(event)}
                      >
                        {t("inviteFriends")}
                      </IonButton>
                    </EventAction>
                    <EventAction>
                      <IonButton
                        color="primary"
                        size="small"
                        onClick={() => onOrderNowClick(event)}
                      >
                        {t("orderNow")}
                      </IonButton>
                    </EventAction>
                  </EventActionsWrapper>
                )}
              </IonItem>
            ))}
          </IonList>
        </EventArea>
      </IonContent>
    </>
  );
};

export default GroupManagementEdit;
