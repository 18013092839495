import axios from "axios";
import store from "../redux/store";
import { setShowApiError } from "../redux/toast.slice";
import { setIsLoading } from "../redux/utility.slice";

const awsRequestClient = axios.create();

awsRequestClient.interceptors.request.use((config) => {
  if (!config?.params?.ignoreLoading) {
    store.dispatch(setIsLoading(true));
  }
  return config;
});

awsRequestClient.interceptors.response.use(
  (response) => {
    store.dispatch(setIsLoading(false));
    return response;
  },
  (error) => {
    store.dispatch(setIsLoading(false));
    store.dispatch(
      setShowApiError({ isShow: true, errorCode: error?.response?.data?.error })
    );
    return Promise.reject(error);
  }
);

export default awsRequestClient;
