import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonText,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { isEventActive } from "../shared/helpers";
import { GroupBuyingGroup } from "../shared/types";
import GroupImage from "./GroupImage";

interface GroupCardProps {
  groupBuyingGroup: GroupBuyingGroup;
  onClick?: React.MouseEventHandler<HTMLIonCardElement> | undefined;
}

const GroupCard: React.FC<GroupCardProps> = ({ groupBuyingGroup, onClick }) => {
  const { t } = useTranslation();
  const system = useSelector((state: RootState) => state.system);

  return (
    <IonCard onClick={onClick}>
      <IonCardHeader>
        <IonCardSubtitle>{groupBuyingGroup?.leader?.name}</IonCardSubtitle>
        <IonCardTitle>{groupBuyingGroup?.name}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <GroupImage
          src={
            groupBuyingGroup?.imageUrl ||
            "https://d1.biteos.com/cas2/uploads/brand/groupepe"
          }
        />
        <IonText color="dark">
          <h2>
            {t("currentEvent")}:{" "}
            {
              groupBuyingGroup?.groupBuyingEvents?.filter((e) =>
                isEventActive(e, system.groupBuyingCutOffDays)
              )?.length
            }
          </h2>
        </IonText>
      </IonCardContent>
    </IonCard>
  );
};

export default GroupCard;
