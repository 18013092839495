import { ErrorMessage } from "@hookform/error-message";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonText,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import { postGroupBuyingGroup } from "../../api/path";
import requestClient from "../../api/request-client";
import FileInput from "../../components/FileInput";
import { setDataOutdated } from "../../redux/managed-group.slice";
import { setShowSubmitSuccess } from "../../redux/toast.slice";
import { uploadFileToAwsS3 } from "../../shared/helpers";
import { GroupBuyingGroup } from "../../shared/types";

interface CreateGroupFormData {
  groupName: string;
  isPrivateGroup: boolean;
  selectedImageFile: File | null;
}

const FormInputArea = styled.div`
  margin-bottom: 30px;
`;

const CreateGroup: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const initialValue: CreateGroupFormData = {
    groupName: "",
    isPrivateGroup: false,
    selectedImageFile: null,
  };
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: { ...initialValue },
  });

  const onSubmit = async (data: CreateGroupFormData) => {
    if (!isValid) return;

    let imageUrl = null;
    if (data.selectedImageFile) {
      imageUrl = await uploadFileToAwsS3(data.selectedImageFile);
    }

    await requestClient.post<GroupBuyingGroup>(postGroupBuyingGroup, {
      name: data.groupName,
      isPrivateGroup: data.isPrivateGroup,
      imageUrl,
    });
    dispatch(setShowSubmitSuccess(true));
    dispatch(setDataOutdated(true));
    history.replace("/group-leader-management");
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/group-leader-management" />
          </IonButtons>
          <IonTitle>{t("createGroup")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{t("createGroup")}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <form onSubmit={handleSubmit(async (data) => await onSubmit(data))}>
          <FormInputArea>
            <IonList>
              <IonItem>
                <IonLabel position="stacked">{t("groupName")}</IonLabel>
                <Controller
                  name="groupName"
                  control={control}
                  rules={{ required: t("requiredField")?.toString() }}
                  render={({ field: { onChange } }) => (
                    <IonInput type="text" onIonChange={onChange} />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="groupName"
                  as={<IonText color="danger" />}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">{t("isPrivateGroup")}</IonLabel>
                <Controller
                  name="isPrivateGroup"
                  control={control}
                  render={({ field }) => (
                    <IonToggle
                      checked={field.value}
                      onIonChange={(e) => {
                        setValue("isPrivateGroup", e.detail.checked);
                      }}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="isPrivateGroup"
                  as={<IonText color="danger" />}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="stacked">{t("groupImage")}</IonLabel>
                <Controller
                  name="selectedImageFile"
                  control={control}
                  render={() => (
                    <FileInput
                      type="file"
                      accept="image/*"
                      onChange={(event) => {
                        setValue(
                          "selectedImageFile",
                          event?.target?.files ? event.target.files[0] : null
                        );
                      }}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="selectedImageFile"
                  as={<IonText color="danger" />}
                />
              </IonItem>
            </IonList>
          </FormInputArea>
          <IonButton expand="block" type="submit">
            {t("submit")}
          </IonButton>
        </form>
      </IonContent>
    </>
  );
};

export default CreateGroup;
