import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GroupBuyingGroup } from "../shared/types";
import { RootState } from "./store";

interface ManagedGroupState {
  data: GroupBuyingGroup[];
  count: number;
  total: number;
  page: number;
  lastUpdateTime?: Date;
  isDataOutdated?: boolean;
}

const initialState: ManagedGroupState = {
  data: [],
  count: 0,
  total: 0,
  page: 0,
};

export const managedGroupSlice = createSlice({
  name: "managedGroup",
  initialState,
  reducers: {
    setManagedGroup: (state, action: PayloadAction<ManagedGroupState>) => {
      state.data = action.payload.data;
      state.count = action.payload.count;
      state.total = action.payload.total;
      state.page = action.payload.page;
      state.lastUpdateTime = new Date();
      state.isDataOutdated = false;
    },
    setDataOutdated: (state, action: PayloadAction<boolean>) => {
      state.isDataOutdated = action.payload;
    },
  },
});

export const selectMangedGroup = (state: RootState) => state.managedGroup.data;
export const { setManagedGroup, setDataOutdated } = managedGroupSlice.actions;
export default managedGroupSlice.reducer;
