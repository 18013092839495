import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { refreshToken } from "../api/path";
import requestClient from "../api/request-client";
import { AuthState } from "../shared/types";
import { RootState } from "./store";

interface MembershipState {
  auth: AuthState | null | undefined;
}

const initialState: MembershipState = {
  auth: null,
};

export const doRefreshToken = createAsyncThunk<
  AuthState | null,
  void,
  { state: RootState }
>("membership/refreshToken", async (arg: any, { getState }) => {
  const state = getState();
  try {
    if (state.membership?.auth) {
      const resp = await requestClient.post<AuthState>(
        refreshToken,
        {
          accessToken: state.membership?.auth?.accessToken,
          refreshToken: state.membership?.auth?.refreshToken,
        },
        {
          params: {
            ignoreLoading: true,
          },
        }
      );
      return resp.data;
    } else {
      return null;
    }
  } catch {
    return null;
  }
});

export const membershipSlice = createSlice({
  name: "membership",
  initialState,
  reducers: {
    setMembership: (state, action: PayloadAction<MembershipState>) => {
      state.auth = action.payload.auth;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doRefreshToken.fulfilled, (state, action) => {
      state.auth = action.payload;
    });
  },
});

export const { setMembership } = membershipSlice.actions;
export default membershipSlice.reducer;
