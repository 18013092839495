import {
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useTranslation } from "react-i18next";

const Settings: React.FC = () => {
  const { t, i18n } = useTranslation();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t("settings")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{t("settings")}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonList>
          <IonRadioGroup
            value={i18n?.language?.substring(0, 2)}
            onIonChange={(e) => i18n.changeLanguage(e.detail.value)}
          >
            <IonListHeader>
              <IonLabel>{t("language")}</IonLabel>
            </IonListHeader>
            <IonItem>
              <IonLabel>{t("english")}</IonLabel>
              <IonRadio slot="end" value="en" />
            </IonItem>
            <IonItem>
              <IonLabel>{t("chinese")}</IonLabel>
              <IonRadio slot="end" value="zh" />
            </IonItem>
          </IonRadioGroup>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Settings;
