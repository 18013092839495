import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SystemState {
  version: string;
  groupBuyingOutletCompanyId: string;
  groupBuyingCutOffDays: number;
}

const initialState: SystemState = {
  version: "",
  groupBuyingOutletCompanyId: "5",
  groupBuyingCutOffDays: 2,
};

export const systemSlice = createSlice({
  name: "system",
  initialState,
  reducers: {
    setSystem: (state, action: PayloadAction<SystemState>) => {
      state.version = action.payload.version;
      state.groupBuyingOutletCompanyId =
        action.payload.groupBuyingOutletCompanyId;
      state.groupBuyingCutOffDays = action.payload.groupBuyingCutOffDays;
    },
  },
});

export const { setSystem } = systemSlice.actions;
export default systemSlice.reducer;
