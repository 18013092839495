import {
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
  IonText,
  IonTitle,
  IonToolbar,
  RefresherEventDetail,
} from "@ionic/react";
import { add } from "ionicons/icons";
import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import { getManagedGroupBuyingGroups } from "../../api/path";
import requestClient from "../../api/request-client";
import GroupCard from "../../components/GroupCard";
import { setManagedGroup } from "../../redux/managed-group.slice";
import { RootState } from "../../redux/store";

const Hints = styled.h4`
  text-align: center;
`;

const GroupLeaderManagement: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const managedGroup = useSelector((state: RootState) => state.managedGroup);
  const ionInfiniteScrollRef: React.RefObject<HTMLIonInfiniteScrollElement> =
    useRef(null);
  const limitSize: number = 5;
  const isMoreToLoad: boolean = managedGroup.total > managedGroup.data.length;
  const sort: string = "createdAt,DESC";
  const filter: string = "";
  // const filter: string = `leader.id||$eq||${membership?.auth?.profileId}`;

  const getManagedGroupApi = async (parameters: any): Promise<any> => {
    const response = await requestClient.get(getManagedGroupBuyingGroups, {
      params: parameters,
      data: {
        ignoreLoading: true,
      },
    });
    return response.data;
  };
  const getLatestManagedGroupCallback = useCallback(async () => {
    const json = await getManagedGroupApi({
      limit: limitSize.toString(),
      page: "1",
      sort,
      filter,
    });
    dispatch(setManagedGroup(json));
  }, [dispatch, filter]);
  useEffect(() => {
    getLatestManagedGroupCallback();
  }, [getLatestManagedGroupCallback, managedGroup.isDataOutdated]);

  const onScrollLoadData = async (e: CustomEvent<void>) => {
    const responseData = await getManagedGroupApi({
      limit: limitSize.toString(),
      page: (managedGroup.page + 1).toString(),
      filter,
      sort,
    });
    dispatch(
      setManagedGroup({
        data: [...managedGroup.data, ...responseData.data],
        count: responseData.count,
        page: responseData.page,
        total: responseData.total,
      })
    );
    ionInfiniteScrollRef?.current?.complete();
  };
  const onRefresh = async (e: CustomEvent<RefresherEventDetail>) => {
    try {
      await getLatestManagedGroupCallback();
    } finally {
      e.detail.complete();
    }
  };

  const cardOnClick = (id: number) => {
    history.push(`/group-leader-management/edit/${id.toString()}`);
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t("groupsYouManage")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{t("groupsYouManage")}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonText color="medium">
          <Hints>{t("theGroupsThatYouAreTheLeader")}</Hints>
        </IonText>
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton routerLink="/group-leader-management/create">
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab>
        {managedGroup?.data?.map((g) => (
          <GroupCard
            key={g.id}
            groupBuyingGroup={g}
            onClick={() => cardOnClick(g.id)}
          />
        ))}
        <IonInfiniteScroll
          ref={ionInfiniteScrollRef}
          threshold="50px"
          onIonInfinite={onScrollLoadData}
          disabled={!isMoreToLoad}
        >
          <IonInfiniteScrollContent loadingSpinner="bubbles"></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </>
  );
};

export default GroupLeaderManagement;
