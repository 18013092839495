export const login = "/auth/customer/login";
export const logout = "/auth/logout";
export const refreshToken = "/auth/refresh";
export const setPasswordApi = "/auth/customer/setPassword";
export const getVerificationCode = "/auth/customer/requestSMSOtpToken";
export const getGroupBuyingGroups = "/public/groupBuyingGroups";
export const getManagedGroupBuyingGroups = "/authCustomer/groupBuyingGroups";
export const postGroupBuyingGroup = "/authCustomer/groupBuyingGroups";
export const getGroupBuyingGroup = "/public/groupBuyingGroups/";
export const getManagedGroupBuyingGroup = "/authCustomer/groupBuyingGroups/";
export const postGroupBuyingEvent =
  "/authCustomer/groupBuyingGroups/{id}/groupBuyingEvents";
export const getGroupBuyingDistricts = "/public/groupBuyingDistricts";
export const getOutlets = "/public/outlets";
export const getSystemConfigs = "/system/configs";
export const getS3PutObjectSignedUrl =
  "/authCustomer/awsS3/getPutObjectSignedUrl/";
