import {
  Action,
  combineReducers,
  configureStore,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import managedGroupReducer from "./managed-group.slice";
import membershipReducer from "./membership.slice";
import publicGroupReducer from "./public-group.slice";
import systemReducer from "./system.slice";
import toastReducer from "./toast.slice";
import utilityReducer from "./utility.slice";

const reducers = combineReducers({
  toast: toastReducer,
  utility: utilityReducer,
  membership: membershipReducer,
  publicGroup: publicGroupReducer,
  managedGroup: managedGroupReducer,
  system: systemReducer,
});
const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    // avoid redux persist issue
    // https://stackoverflow.com/questions/61704805/getting-an-error-a-non-serializable-value-was-detected-in-the-state-when-using
    getDefaultMiddleware({ serializableCheck: false }),
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;
export const useAppThunkDispatch = () => useDispatch<ThunkAppDispatch>();
export default store;
