import {
  IonApp,
  IonIcon,
  IonLabel,
  IonLoading,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonToast,
} from "@ionic/react";
import { IonReactHashRouter } from "@ionic/react-router";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import { flame, peopleCircle, personCircle, settings } from "ionicons/icons";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { getSystemConfigs } from "./api/path";
import requestClient from "./api/request-client";
import CreateEvent from "./pages/auth-require/CreateEvent";
import CreateGroup from "./pages/auth-require/CreateGroup";
import GroupLeaderManagement from "./pages/auth-require/GroupLeaderManagement";
import GroupManagementEdit from "./pages/auth-require/GroupManagementEdit";
import Membership from "./pages/auth-require/Membership";
import AuthRequireWrapping from "./pages/AuthRequireWrapping";
import AvailableGroup from "./pages/AvailableGroup";
import GroupDetail from "./pages/GroupDetail";
import Settings from "./pages/Settings";
import { doRefreshToken } from "./redux/membership.slice";
import { RootState } from "./redux/store";
import { setSystem } from "./redux/system.slice";
import { setShowApiError, setShowSubmitSuccess } from "./redux/toast.slice";
/* Theme variables */
import "./theme/variables.css";

const App: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const showSubmitSuccess = useSelector(
    (state: RootState) => state.toast.showSubmitSuccess
  );
  const showApiError = useSelector(
    (state: RootState) => state.toast.showApiError
  );
  const errorCode = useSelector((state: RootState) => state.toast.errorCode);
  const isLoading = useSelector((state: RootState) => state.utility.isLoading);

  const [refreshTokenTimerId, setRefreshTokenTimerId] = useState<number>();

  const refreshTokenTimer = useCallback(() => {
    const newId = window.setTimeout(async () => {
      dispatch(doRefreshToken());
      refreshTokenTimer();
    }, 5000);
    setRefreshTokenTimerId(newId);
  }, [dispatch]);
  const getSettings = useCallback(async () => {
    const response = await requestClient.get(getSystemConfigs);
    dispatch(setSystem(response.data));
  }, [dispatch]);

  useEffect(() => {
    getSettings();
    refreshTokenTimer();
  }, [refreshTokenTimer, getSettings]);

  return (
    <IonApp>
      <IonReactHashRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Route exact path="/">
              <Redirect to="/available-group" />
            </Route>
            <Route exact path="/available-group">
              <AvailableGroup />
            </Route>
            <Route exact path="/group-leader-management">
              <AuthRequireWrapping
                component={() => <GroupLeaderManagement />}
              />
            </Route>
            <Route exact path="/group-leader-management/create">
              <AuthRequireWrapping component={() => <CreateGroup />} />
            </Route>
            <Route exact path="/group-leader-management/edit/:id">
              <AuthRequireWrapping component={() => <GroupManagementEdit />} />
            </Route>
            <Route exact path="/group-leader-management/edit/:id/create-event">
              <AuthRequireWrapping component={() => <CreateEvent />} />
            </Route>
            <Route exact path="/membership">
              <AuthRequireWrapping component={() => <Membership />} />
            </Route>
            <Route exact path="/settings">
              <Settings />
            </Route>
            <Route exact path="/available-group/:id">
              <GroupDetail />
            </Route>
          </IonRouterOutlet>
          <IonTabBar slot="bottom">
            <IonTabButton tab="available-group" href="/available-group">
              <IonIcon icon={flame} />
              <IonLabel>{t("availableGroup")}</IonLabel>
            </IonTabButton>
            <IonTabButton
              tab="group-leader-management"
              href="/group-leader-management"
            >
              <IonIcon icon={peopleCircle} />
              <IonLabel>{t("groupLeaderManagement")}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="membership" href="/membership">
              <IonIcon icon={personCircle} />
              <IonLabel>{t("membership")}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="settings" href="/settings">
              <IonIcon icon={settings} />
              <IonLabel>{t("settings")}</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactHashRouter>
      <IonToast
        isOpen={showApiError}
        onDidDismiss={() => dispatch(setShowApiError({ isShow: false }))}
        message={t(errorCode || "apiError")}
        duration={5000}
        color="danger"
        position="middle"
        buttons={[
          {
            text: t("close"),
            role: "cancel",
          },
        ]}
      />
      <IonToast
        isOpen={showSubmitSuccess}
        onDidDismiss={() => dispatch(setShowSubmitSuccess(false))}
        message={t("submitSuccess")}
        duration={5000}
        color="success"
        position="bottom"
        buttons={[
          {
            text: t("close"),
            role: "cancel",
          },
        ]}
      />
      <IonLoading isOpen={isLoading} message={t("pleaseWait") + "..."} />
    </IonApp>
  );
};

export default App;
